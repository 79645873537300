// export const BASE_URL = 'http://192.168.29.241:1000/v1'; //harita
// export const BASE_URL = 'http://192.168.29.135:1000/v1'; // sidharth
// export const BASE_URL = 'http://192.168.29.59:1000/v1'; // jignesh
// export const BASE_URL = "https://ad-anima.com:8081/v1"; //development
export const BASE_URL = "https://ad-anima.com:8080/v1"; //STAGING
// export const BASE_URL = 'https://ad-anima.com:8082/v1'; // live
// export const BASE_URL = 'http://192.168.29.147:1000/v1'; //krushang
// export const BASE_URL_V2 = 'http://192.168.29.60:1000/v2'; // jignesh


// export const imgUrl = 'http://192.168.29.59:1000/public/'; // jignesh
// export const imgUrl = 'http://192.168.29.241:1000/public/'; // harita
// export const imgUrl = 'https://ad-anima.com:8081/public/';//development 
export const imgUrl = 'https://ad-anima.com:8080/public/'; //STAGING
// export const imgUrl = 'https://ad-anima.com:8082/public/'; // live

export const ENVIROMENT = "STAGE";
// export const ENVIROMENT = "DEV";
// export const ENVIROMENT = "LIVE";